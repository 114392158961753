
export const homeObjOne = {
    lightBg: false,
    primary: true,
    imgStart: '',
    lightTopLine: true,
    lightTextDesc: true,
    buttonLabel: 'Get Started',
    description: 'We help business owners increase their revenue. Our team of unique specialist can help you acheive your business goals.',
    headline: 'Lead Generation Specialist for Online Businesses',
    lightText:true,
    topLine: 'Marketing Agency',
    img: require('../../images/svg-1.svg').default,
    alt: 'image',
    start: false
  };
  
  export const homeObjTwo= {
    lightBg: true,
    primary: false,
    imgStart: 'start',
    lightTopLine: false,
    lightTextDesc: false,
    buttonLabel: 'Get Started',
    description: 'We help business owners increase their revenue. Our team of unique specialist can help you acheive your business goals.',
    headline: 'Lead Generation Specialist for Online Businesses',
    lightText:false,
    topLine: 'Marketing Agency',
    img: require('../../images/profile.jpg'),
    alt: 'image',
    start: true
  };
  
  export const homeObjThree= {
    lightBg: false,
    primary: true,
    imgStart: '',
    lightTopLine: true,
    lightTextDesc: true,
    buttonLabel: 'Get Started',
    description: 'We help business owners increase their revenue. Our team of unique specialist can help you acheive your business goals.',
    headline: 'Lead Generation Specialist for Online Businesses',
    lightText:true,
    topLine: 'Marketing Agency',
    img: require('../../images/svg-2.svg').default,
    alt: 'image',
    start: false
  };
  
  export const homeObjFour= {
    lightBg: false,
    primary: true,
    imgStart: 'start',
    lightTopLine: true,
    lightTextDesc: true,
    buttonLabel: 'Get Started',
    description: 'We help business owners increase their revenue. Our team of unique specialist can help you acheive your business goals.',
    headline: 'Lead Generation Specialist for Online Businesses',
    lightText:true,
    topLine: 'Marketing Agency',
    img: require('../../images/svg-3.svg').default,
    alt: 'image',
    start: true
  };